import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { CardHeader } from '@mui/material';

interface CardHeaderListInfoProps {
  children?: ReactNode;
  title?:string | ReactNode;
}

export const cardHeaderTitle = {
  fontWeight: 'bold'
};

const CardHeaderListInfo: FC<CardHeaderListInfoProps> = ({ title }) => <CardHeader title={title} titleTypographyProps={{...cardHeaderTitle, variant: 'subtitle1'}} />

CardHeaderListInfo.propTypes = {
  title: PropTypes.string
};

export default CardHeaderListInfo;
