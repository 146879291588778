import { ErrorType, SchoolEstablishmentModel } from 'src/common';
import * as Yup from 'yup';

function verifyDomain(value:string) : boolean {
  if (value) {
    var patt = /@+/;
    if(!patt.test(value)){
      patt = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/gm
      return patt.test(value);
    }
  }
  return false;
}

const validateSchema = (model: SchoolEstablishmentModel) => {
  const { Domain, NotarialSubscriptionGroupId } = model;
  return Yup.object().shape({
    name: model.Name.required(true),
    emailDomains: Yup.array().of(
      Yup.object().shape({
        domain: Domain.required(true)
          .test(Domain.Label, SchoolEstablishmentModel.formatError(ErrorType.invalid, Domain.Label),(value: string) => {
            return verifyDomain(value);
          })
      })
    ),
    notarialSubscriptionGroupId: Yup.string().when('notarialLawMasterOffer',{
       is: (notarialLawMasterOffer: boolean) => notarialLawMasterOffer === true,
       then: NotarialSubscriptionGroupId.required().nullable(),
       otherwise: Yup.string().nullable()
    }),
    subscriptionGroupId: model.SubscriptionGroupId.required().nullable(),
  })
};

export default validateSchema;