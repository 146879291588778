import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { 
  HandleChange, 
  SchoolEstablishmentEmailDomainDto, 
  SchoolEstablishmentResource, 
  SetFieldValue, 
} from 'src/common/types';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import {
  CaijInput,
  Link as RouterLink,
  FormikErrors,
  FormikTouched,
  SchoolEstablishmentModel
} from 'src/common';
import { PlusSquare as AddIcon, Trash as DeleteIcon  } from 'react-feather';

export interface EmailDomainProps {
  disabled: boolean;
  model: SchoolEstablishmentModel;
  schoolEstablishment: SchoolEstablishmentResource;
  errors: FormikErrors<SchoolEstablishmentResource>;
  touched: FormikTouched<SchoolEstablishmentResource>;
  handleChange: HandleChange;
  setFieldValue: SetFieldValue;
}

const EmailDomain: FC<EmailDomainProps> = ({
  model,
  disabled,
  schoolEstablishment,
  errors,
  touched,
  handleChange,
  setFieldValue
}) => {
  const { EmailDomain, Domain } = model;
  const { emailDomains } = schoolEstablishment;
  const error = (errors.emailDomains as SchoolEstablishmentEmailDomainDto[]);

  const deleteEmailDomain = (index: number) => {
    const newEmailDomain = [...emailDomains];
    newEmailDomain.splice(index,1);
    setFieldValue(EmailDomain, newEmailDomain);
  }

  return (
    <>
      { emailDomains && emailDomains.map((row, index) => (
          <Box key={index} sx={{ml: 1}}>
            <Typography sx={{padding: `${index != 0 ? '10px' : '0' } 0 10px 0`}}>{model.getEmailDomainTitle(index + 1)}</Typography>
            <CaijInput 
              required
              name={`${EmailDomain}.${index}.${Domain.Name}`}
              label={Domain.Label}
              value={row.domain}
              error={Boolean((touched.emailDomains && (touched.emailDomains[index]?.domain || false)) && (error && (error[index]?.domain || false)))}
              helperText={((touched.emailDomains && (touched.emailDomains[index]?.domain || false)) && (error && (error[index]?.domain || false))) || ' '}
              InputLabelProps={{ shrink: true, required: true }}
              inputAttr={{ maxLength: Domain.MaxLength}}
              onHandleChange={handleChange}
              setFieldValue={setFieldValue}
            />
            <Typography variant='body2' color="error" sx={{mb:2}}>Exemple : umontreal.ca</Typography>
            <Button
              startIcon={
                <SvgIcon fontSize='small'>
                  <DeleteIcon />
                </SvgIcon>
              }
              onClick={() => deleteEmailDomain(index)}
            >
              Supprimer le domaine
            </Button>
          </Box>
        ))
      }
      <Button
        disabled={disabled} 
        to={'#'}
        component={RouterLink}
        onClick={(e:React.MouseEvent<HTMLAnchorElement>) => {
          let emailDomain = { name: ''};
          if(schoolEstablishment.emailDomains){
            setFieldValue(EmailDomain, [...schoolEstablishment.emailDomains, { name: ''}]);
          }else{
            setFieldValue(EmailDomain, [emailDomain]);
          }
        }}
        sx={{mt: 2, padding: '10px'}}
      >
        <Box display="flex" alignItems="center">
          <SvgIcon fontSize='small' sx={{mr: 0.8}}>
            <AddIcon />
          </SvgIcon>
          <Typography sx={{fontSize: '14px'}}>Ajouter un domaine</Typography>
        </Box>
      </Button>
    </>
  )
};

EmailDomain.propTypes = {
  schoolEstablishment: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default EmailDomain;
