import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppModel from 'src/model/App';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    const model = new AppModel();
    return <Navigate to={model.PathName || '/app'} />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default GuestGuard;
