import { ReactNode, useMemo } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';

interface MemoProps {
  children: ReactNode;
}

const Memo : FC<MemoProps> = ({children}) => (<>{useMemo(() => children, [])}</>);

export default Memo;
