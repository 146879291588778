import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  handleChange,
  handleChangeCheckBox,
  handleChangeImageUrl,
  CaijButtonReset,
  CaijButtonSubmit,
  Authorize,
  _libraryType,
  LibraryModel,
  Mode,
  btnSubmit,
  LibraryOpenHoursModel
} from 'src/common';
import type {
  LibraryResource,
  LibraryResourceForEdit,
} from 'src/common/types';
import printMessage from 'src/views/errors/MessageError';
import LibraryForm from 'src/components/library/library/Forms';
import { Root } from 'src/components/styled';
import MyCard from 'src/components/card/MyCard';
import { validateDetailSchema } from '../../Schema';
import Logo from 'src/components/library/library/logo';
import CaijLocalDraftEditor from 'src/components/draftEditor/CaijLocalDraftEditor';

export interface DetailsEditFormProps {
  model: LibraryModel;
  libraryOpenHoursModel: LibraryOpenHoursModel;
  library: LibraryResource;
  onHandleRefreshLibrary: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: LibraryResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  libraryOpenHoursModel,
  library,
  onHandleRefreshLibrary,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const { TempClosingMsgFr, TempClosingMsgEn, DescriptionFr, DescriptionEn, QuoteFr, QuoteEn, StaffFr, StaffEn } = model;

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: LibraryResource): Promise<void> => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new LibraryModel();
    const submitData = values as LibraryResourceForEdit;
    const libraryId = submitData.id
    if (submitData && libraryId) {
      model.saveRecords(values,submitData);
      submitData.openHours = library.openHours;
      const response = await model.updateLibrary(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        await onHandleRefreshLibrary();
      }
    }
  };

  return (
    <Formik
      initialValues={LibraryModel.getInitialValues(library)}
      validationSchema={validateDetailSchema(model)}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Root>
                <LibraryForm 
                  model={model}
                  libraryOpenHoursModel={libraryOpenHoursModel}
                  library={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  onHandleChange={handleChange}
                  onHandleChangeCheckBox={handleChangeCheckBox}
                  setFieldValue={setFieldValue}
                />  
              </Root>
              <Grid container spacing={2}>
                <CaijLocalDraftEditor
                  formModel={{propFr: TempClosingMsgFr, propEn: TempClosingMsgEn}}
                  data={{valFr: values.tempClosingMsgFr, valEn: values.tempClosingMsgEn}}
                  setFieldValue={setFieldValue}
                />
                <CaijLocalDraftEditor
                  formModel={{propFr: DescriptionFr, propEn: DescriptionEn}}
                  data={{valFr: values.descriptionFr, valEn: values.descriptionEn}}
                  setFieldValue={setFieldValue}
                />
                <CaijLocalDraftEditor
                  formModel={{propFr: QuoteFr, propEn: QuoteEn}}
                  data={{valFr: values.quoteFr, valEn: values.quoteEn}}
                  setFieldValue={setFieldValue}
                />
                <CaijLocalDraftEditor
                  formModel={{propFr: TempClosingMsgFr, propEn: TempClosingMsgEn}}
                  data={{valFr: values.tempClosingMsgFr, valEn: values.tempClosingMsgEn}}
                  setFieldValue={setFieldValue}
                />
                <CaijLocalDraftEditor
                  formModel={{propFr: StaffFr, propEn: StaffEn}}
                  data={{valFr: values.staffFr, valEn: values.staffEn}}
                  setFieldValue={setFieldValue}
                />
                <Logo
                  model={model}
                  library={values}  
                  setFieldValue={setFieldValue}
                  handleChangeImageUrl={handleChangeImageUrl}
                /> 
              </Grid>
              <Box>
                <Authorize resourceCode={model.ResourceCode} mode={Mode.edit} onIsAuth={l => setIsAuth(l)}>
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  library: PropTypes.object.isRequired,
  onHandleRefreshLibrary: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
