import React from 'react';
import type { FC, ReactNode, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Button, SvgIcon } from '@mui/material';
import { 
  CheckSquare as CheckIcon,
  PlusCircle as PlusCircleIcon,
  Edit as EditIcon,
  XSquare as CancelIcon,
  
} from 'react-feather';

interface CaijButtonProps {
  type?: "default" | "add" | "edit" | "cancel";
  disabled?: boolean;
  children?: ReactNode;
  onHandleClick?: (event?: ChangeEvent<any>) => void;
  sx?:any;
  color?: "primary" | "secondary" | 'success' | 'error' | 'info' | 'warning'| 'inherit';
  variant?: "outlined" | "contained" | "text";
  startIcon?: ReactNode;
}

const CaijButtonSave: FC<CaijButtonProps> = ({
  type,
  disabled,
  children,
  onHandleClick,
  color,
  sx,
  variant,
  startIcon
}) => {
  
  const renderContent = (): JSX.Element => {
     if(type === "add") {
       return (
          <Button
            type="button"
            variant={variant}
            disabled={disabled}
            color={color}
            sx={sx}
            onClick={onHandleClick}
            startIcon={(
              <SvgIcon fontSize="small">
                <PlusCircleIcon />
              </SvgIcon>
            )}
          >
            {children}
          </Button>
        )
     } else if(type === "edit") {
        return ( 
          <Button
            type="button"
            variant={variant}
            disabled={disabled}
            color={color}
            sx={sx}
            onClick={onHandleClick}
            startIcon={(
              <SvgIcon fontSize="small">
                <EditIcon />
              </SvgIcon>
            )}
          >
            {children}
          </Button>
        )
     }else if(type === "cancel") {
        return (
          <Button
            type="button"
            variant={variant}
            disabled={disabled}
            color={color}
            sx={sx}
            onClick={onHandleClick}
            startIcon={(
              <SvgIcon fontSize="small">
                 <CancelIcon />
               </SvgIcon>
            )}
          >
            {children}
          </Button>
        )
     } else{
        return (
          <Button
            type="button"
            variant={variant}
            disabled={disabled}
            color={color}
            sx={sx}
            onClick={onHandleClick}
            startIcon={startIcon ? startIcon :
              <SvgIcon fontSize="small">
                 <CheckIcon />
               </SvgIcon>
            }
          >
            {children}
          </Button>
        )
     }
  };

  return (
  <>
    { renderContent() }
  </>
);
}

CaijButtonSave.propTypes = {
  disabled: PropTypes.bool,
  onHandleClick: PropTypes.func,
  sx:PropTypes.any,
  type: PropTypes.oneOf(['default', 'add', 'edit', 'cancel']),
  color: PropTypes.oneOf(['primary', 'secondary', 'success' , 'error' , 'info' , 'warning', 'inherit']),
  variant: PropTypes.oneOf(["outlined", "contained", "text"])
};

CaijButtonSave.defaultProps = {
  disabled: false,
  color: 'primary',
  variant: 'contained',
  type: 'default'
};

export default CaijButtonSave;
