import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent } from '@mui/material';

interface CaijCardProps {
  title?: string;
  children?: ReactNode;
  elevation?: number;
  action?: ReactNode;
  sx?: Object;
  allowedBackgroundColor?: boolean;
  required?: boolean;
}

export const cardHeaderTitle = {
  backgroundColor: '#20639F',
  borderRadius: '5px 5px 5px 5px',
  fontWeight: 'bold',
  padding: '0.7em 0 0.7em 1em',
  color: '#ffffff'
};

const cardHeaderTitle2 = {
  borderRadius: '5px 5px 5px 5px',
  fontWeight: 'bold',
  padding: '0.7em 0 0.7em 1em',
  color:'text.caijCard'
};

const CaijCard: FC<CaijCardProps> = ({ title, elevation, children, action, sx, allowedBackgroundColor, required}) => (
  <Card elevation={elevation} sx={{ ...sx, background: theme => theme.palette.background.paper}}>
    <CardHeader title={required ? `${title} *` : title} sx={!allowedBackgroundColor ? cardHeaderTitle2 : cardHeaderTitle} titleTypographyProps={{variant: 'subtitle1'}} action={action}/>
    <CardContent>{children}</CardContent>
  </Card>
);

CaijCard.propTypes = {
  title: PropTypes.string,
  elevation: PropTypes.number,
  sx: PropTypes.object,
  allowedBackgroundColor: PropTypes.bool,
  required: PropTypes.bool
};

CaijCard.defaultProps = {
  sx:{mb: 2},
  allowedBackgroundColor: true
}

export default CaijCard;
