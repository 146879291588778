import React, { useEffect, useState }  from 'react';
import type { ChangeEvent, FC } from 'react';
import PropTypes from 'prop-types';
import type { 
  HandleBlur, 
  HandleChange, 
  SchoolEstablishmentResource, 
  SetFieldValue 
} from 'src/common/types';
import { Box, Grid, TextField } from '@mui/material';
import {
  CaijInput,
  FormikErrors,
  FormikTouched,
  CaijSwitch,
  SchoolEstablishmentModel,
  CaijCard,
  EMAIL_DOMAIN,
  getOptionLabel
} from 'src/common';
import EmailDomain from './EmailDomain';
import SchoolEstablishmentDialog from '../dialog/schoolEstablishment';
import { useSchoolEstablishmentGroups } from 'src/functions/hooks/group';

interface SchoolEstablishmentFormProps {
  model: SchoolEstablishmentModel;
  schoolEstablishment: SchoolEstablishmentResource;
  errors: FormikErrors<SchoolEstablishmentResource>;
  touched: FormikTouched<SchoolEstablishmentResource>;
  handleBlur: HandleBlur;
  handleChange: HandleChange;
  SetFieldValue: SetFieldValue;
}

const SchoolEstablishmentForm: FC<SchoolEstablishmentFormProps> = ({
  model,
  schoolEstablishment,
  errors,
  touched,
  handleBlur,
  handleChange,
  SetFieldValue
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { Name, ValidateEmailDomain, NotarialLawMasterOffer, SubscriptionGroupId, NotarialSubscriptionGroupId } = model;
  const { groupList, allChecked } = useSchoolEstablishmentGroups();
  
  useEffect(() => {
    setDisabled(!schoolEstablishment.validateEmailDomain);
  },[schoolEstablishment.validateEmailDomain]);

  return (
    <Grid item md={6} sm={12} xs={12}>
      <CaijInput
        name={Name.Name}
        required
        value={schoolEstablishment.name}
        label={Name.Label}
        error={Boolean(touched.name && errors.name)}
        helperText={touched.name && errors.name}
        onHandleChange={handleChange}
        onHandleBlur={handleBlur}
        inputAttr={{maxLength: Name.MaxLength, 'data-testid' : Name.Name}}
        InputLabelProps={{ shrink: true, required: true }}
      />
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <CaijSwitch
          name={NotarialLawMasterOffer.Name}
          checked={schoolEstablishment.notarialLawMasterOffer}
          value={schoolEstablishment.notarialLawMasterOffer}
          inputProps={{ 'data-testid': NotarialLawMasterOffer.Name }}
          onHandleChangeSwitch={(e: any) => {
            const { checked } =  e.target;
            if(!checked)
              schoolEstablishment.notarialSubscriptionGroupId = null
            SetFieldValue(NotarialLawMasterOffer.Name, checked);
          }}
          label={NotarialLawMasterOffer.Label}
          sx={{mb:1}}
        />
        { schoolEstablishment.notarialLawMasterOffer && (
            <TextField
              required
              label={NotarialSubscriptionGroupId.Label}
              id={NotarialSubscriptionGroupId.Name}
              name={NotarialSubscriptionGroupId.Name}
              onChange={(e) => SetFieldValue(NotarialSubscriptionGroupId.Name, e.target.value)}
              select
              SelectProps={{ native: true }}
              value={schoolEstablishment.notarialSubscriptionGroupId || ''}
              variant='outlined'
              helperText={touched.notarialSubscriptionGroupId && errors.notarialSubscriptionGroupId}
              error={Boolean(touched.notarialSubscriptionGroupId && errors.notarialSubscriptionGroupId)}
              InputLabelProps={{ shrink: true, required: true }}
              inputProps={{ 'data-testid': NotarialSubscriptionGroupId.Name }}
              sx={{mb: 2, mt: 2}}
            >
            <option disabled value=''>{getOptionLabel(NotarialSubscriptionGroupId.Label)}</option>
            { allChecked && groupList.map(({id, name}) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))
            }
          </TextField>
          )
        }
        <TextField
          required
          label={SubscriptionGroupId.Label}
          id={SubscriptionGroupId.Name}
          name={SubscriptionGroupId.Name}
          onChange={(e) => SetFieldValue(SubscriptionGroupId.Name, e.target.value)}
          select
          SelectProps={{ native: true }}
          value={schoolEstablishment.subscriptionGroupId || ''}
          variant='outlined'
          helperText={touched.subscriptionGroupId && errors.subscriptionGroupId}
          error={Boolean(touched.subscriptionGroupId && errors.subscriptionGroupId)}
          InputLabelProps={{ shrink: true, required: true }}
          inputProps={{ 'data-testid': SubscriptionGroupId.Name }}
          sx={{mt: 2, mb: 3}}
        >
          <option disabled value=''>{getOptionLabel(SubscriptionGroupId.Label)}</option>
          { allChecked && groupList.map(({id, name}) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))
          }
        </TextField>
      </Box>
      <CaijSwitch
        name={ValidateEmailDomain.Name}
        checked={schoolEstablishment.validateEmailDomain}
        value={schoolEstablishment.validateEmailDomain}
        inputProps={{ 'data-testid': ValidateEmailDomain.Name }}
        onHandleChangeSwitch={(e: ChangeEvent<HTMLInputElement>) => {
          const hasChecked = e.target.checked;
          if(hasChecked){
            setDisabled(false);
            setOpen(hasChecked);
          }else{
            setDisabled(true);
            schoolEstablishment.emailDomains = [];
          }
          SetFieldValue(ValidateEmailDomain.Name, e.target.checked);
        }}
        label={ValidateEmailDomain.Label}
        sx={{mb: 3}}
      />
      <CaijCard title={EMAIL_DOMAIN}>
        <EmailDomain 
          disabled={disabled}
          model={model}
          schoolEstablishment={schoolEstablishment}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          setFieldValue={SetFieldValue}
        />
      </CaijCard>
      <SchoolEstablishmentDialog 
        model={model} 
        open={open} 
        submit={() => { 
          setDisabled(false); 
          setOpen(false); 
        }} 
      />
    </Grid> 
  );
};

SchoolEstablishmentForm.propTypes = {
  schoolEstablishment: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default SchoolEstablishmentForm;