import React, { useState, useRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Grid, Typography } from '@mui/material';
import type {
  LibraryResource,
  LibraryResourceForCreate,
  LibraryResourceForEdit,
  Error,
  CreateResponse,
  WorkingHour,
  GoogleMaps
} from 'src/common/types';
import {
  Formik,
  handleChange,
  handleChangeCheckBox,
  handleChangeImageUrl,
  CaijButtonReset,
  CaijButtonSubmit,
  LibraryModel,
  _libraryType,
  Authorize,
  Mode,
  FormikProps,
  ListType,
  btnSubmit,
  LibraryOpenHoursModel
} from 'src/common';
import Databanks from 'src/components/listInfo/checkList';
import LibraryForm from 'src/components/library/library/Forms';
import RequireParameterList from 'src/components/requiredParameter';
import MyCard from 'src/components/card/MyCard';
import { Root } from 'src/components/styled';
import Memo from 'src/components/memo';
import { useLibraryServices } from 'src/components/requiredParameter/hook';
import { validateEditSchema } from '../Schema';
import Logo from 'src/components/library/library/logo';
import ContactAddress from 'src/components/library/library/ContactAddress';
import CaijLocalDraftEditor from 'src/components/draftEditor/CaijLocalDraftEditor';

export interface LibraryEditFormProps {
  model: LibraryModel;
  libraryOpenHoursModel: LibraryOpenHoursModel;
  library: LibraryResource;
  onSubmit?: (values: LibraryResource) => void;
}

type TypeResourceForEdit = LibraryResourceForCreate | LibraryResourceForEdit;

async function redirect(model: LibraryModel, response: Error | CreateResponse) : Promise<void>{
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

const LibraryEditForm: FC<LibraryEditFormProps> = ({
  model,
  libraryOpenHoursModel,
  library,
  onSubmit,
}) => {
  const [selectedOpenHours, setSelectedOpenHours] = useState<WorkingHour[]>([]);
  const [isListValid, setIsListValid] = useState<boolean>(true);
  const formRef = useRef<FormikProps<LibraryResource>>(null);
  const [isAuth, setIsAuth] = useState<boolean>();
  const [selectedLibraryDatabanks, setSelectedLibraryDatabanks] = useState<{ data: number[]; selected: boolean }>({data: [],selected: false});
  const [selectedLibraryLibraryServices, setSelectedLibraryLibraryServices] = useState<{ data: number[]; selected: boolean }>({data: [],selected: false});
  const [selectedLibraryLibraryServiceParameters, setSelectedLibraryLibraryServiceParameters] = useState<Map<number, string>>();
  const { libraryServices } = useLibraryServices();
  const { TempClosingMsgFr, TempClosingMsgEn, DescriptionFr, DescriptionEn, QuoteFr, QuoteEn, StaffFr, StaffEn } = model;

  const saveListRecords = (model: LibraryModel, submitData: TypeResourceForEdit) => {
    model.SelectedLibraryLibraryServiceParameters = selectedLibraryLibraryServiceParameters;
    model.saveListLibraryService(selectedLibraryLibraryServices, submitData, libraryServices);
    model.saveListDatabank(selectedLibraryDatabanks, submitData);
  };

  const handleSubmit = async(values: LibraryResource) => {
    if (onSubmit){
      onSubmit(values);
      return;
    }
    if(!isListValid) return;
    const model = new LibraryModel();
    let submitData = values as LibraryResourceForEdit;
    const coord : GoogleMaps = await model.handleGeolocation(submitData);
    submitData.latitude = +coord?.latitude;
    submitData.longitude = +coord?.longitude;
    if (submitData && submitData.id) {
      model.setSelectedOpenHours(selectedOpenHours);
      model.saveRecords(values, submitData);
      saveListRecords(model, submitData);
      const response = await model.updateLibrary(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    } else {
      submitData = values as LibraryResourceForCreate;
      model.setSelectedOpenHours(selectedOpenHours);
      model.saveRecords(values, submitData);
      saveListRecords(model, submitData);
      delete submitData.id;
      const response = await model.insertLibrary(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  };

  return (
    <Formik<LibraryResource>
      innerRef={formRef}
      initialValues={LibraryModel.getInitialValues(library)}
      validateOnChange={false}
      validationSchema={validateEditSchema(model)}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Typography variant="subtitle1">
                    Toutes modifications à la fiche de cette bibliothèque doivent être communiquées à l'équipe des Communications.
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Root>
                    <LibraryForm 
                      model={model}
                      libraryOpenHoursModel={libraryOpenHoursModel}
                      library={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      onHandleChange={handleChange}
                      onHandleChangeCheckBox={handleChangeCheckBox}
                      handleOpenHoursChange={(l) => setSelectedOpenHours(l)}
                      setFieldValue={setFieldValue}
                    /> 
                  </Root>
                </Grid>
                <CaijLocalDraftEditor
                  formModel={{propFr: TempClosingMsgFr, propEn: TempClosingMsgEn}}
                  data={{valFr: values.tempClosingMsgFr, valEn: values.tempClosingMsgEn}}
                  setFieldValue={setFieldValue}
                />
                <CaijLocalDraftEditor
                  formModel={{propFr: DescriptionFr, propEn: DescriptionEn}}
                  data={{valFr: values.descriptionFr, valEn: values.descriptionEn}}
                  setFieldValue={setFieldValue}
                />
                <CaijLocalDraftEditor
                  formModel={{propFr: QuoteFr, propEn: QuoteEn}}
                  data={{valFr: values.quoteFr, valEn: values.quoteEn}}
                  setFieldValue={setFieldValue}
                />
                <CaijLocalDraftEditor
                  formModel={{propFr: StaffFr, propEn: StaffEn}}
                  data={{valFr: values.staffFr, valEn: values.staffEn}}
                  setFieldValue={setFieldValue}
                />
                <Logo
                  model={model}
                  library={values}  
                  setFieldValue={setFieldValue}
                  handleChangeImageUrl={handleChangeImageUrl}
                /> 
                <ContactAddress
                  model={model}
                  library={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
                <Grid item md={12} xs={12}>
                  <Memo>
                    <Databanks
                      listType={ListType.DatabankLibrary}
                      onHandleSelectedItems={l =>
                        setSelectedLibraryDatabanks({
                          ...selectedLibraryDatabanks,
                          data: l as number[],
                          selected: true,
                        })
                      }
                      lists={values.databanks}
                    />
                  </Memo>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Memo>
                    <RequireParameterList
                      setIsListValid={(l) => setIsListValid(l)}
                      listType={ListType.LibraryService}
                      lists={values.libraryServices}
                      onHandleItemsSelected={l =>
                        setSelectedLibraryLibraryServices({
                          ...selectedLibraryLibraryServices,
                          data: l,
                          selected: true,
                        })}
                      onHandleRequireParmeters={l => setSelectedLibraryLibraryServiceParameters(l)}
                    />
                  </Memo>
                </Grid>
              </Grid>
              <Box>
                <Authorize resourceCode={model.ResourceCode} mode={library.id ? Mode.edit : Mode.add} onIsAuth={l => setIsAuth(l)}>
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

LibraryEditForm.propTypes = {
  library: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};

LibraryEditForm.defaultProps = {
  onSubmit: null
};

export default LibraryEditForm;


