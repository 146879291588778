import React from 'react';
import type { SetFieldValue } from 'src/common/types';
import { Grid } from '@mui/material';
import CaijDraftEditor from 'src/components/draftEditor/CaijDraftEditor';
import { FormModel } from 'src/model/Form';

type Model<T> = {
  propFr: T;
  propEn: T;
};

type Data<V> = {
  valFr?: V;
  valEn?: V;
};

interface CaijLocalDraftEditorProps<T,V> {
  formModel: T;
  data: V;
  allowedBackgroundColor?: boolean;
  setFieldValue: SetFieldValue;
}

const CaijLocalDraftEditor = <T extends Model<FormModel>,V extends Data<string>>({ 
  formModel, 
  data, 
  allowedBackgroundColor = false,
  setFieldValue 
}: CaijLocalDraftEditorProps<T,V>) => {
  const { propFr, propEn } = formModel;
  return (
    <>
      <Grid item md={6} xs={12}>
        <CaijDraftEditor
          title={propFr.Label}
          dataTestId={propFr.Name}
          value={data.valFr}
          name={propFr.Name}
          setFieldValue={setFieldValue}
          allowedBackgroundColor={allowedBackgroundColor}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijDraftEditor
          title={propEn.Label}
          dataTestId={propEn.Name}
          value={data.valEn}
          name={propEn.Name}
          setFieldValue={setFieldValue}
          allowedBackgroundColor={allowedBackgroundColor}
        />
      </Grid>
    </>
  );
};

export default CaijLocalDraftEditor;