import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { SchoolEstablishmentResource } from 'src/common/types';
import { Divider, Table, TableBody } from '@mui/material';
import { tableRow, SchoolEstablishmentModel } from 'src/common';
import { tableCell } from 'src/styles/subscription';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow, CaijTableRowConfirm } from 'src/components/table/CaijTableRow';
import { useSchoolEstablishmentGroups } from 'src/functions/hooks/group';

export interface SchoolEstablishmentInfoProps {
  model: SchoolEstablishmentModel,
  schoolEstablishment: SchoolEstablishmentResource;
}

const SchoolEstablishmentInfo: FC<SchoolEstablishmentInfoProps> = ({
  model,
  schoolEstablishment
}) => {
  const { name, notarialLawMasterOffer, validateEmailDomain, notarialSubscriptionGroupId, subscriptionGroupId } = schoolEstablishment;
  const { Name, ValidateEmailDomain, NotarialLawMasterOffer, SubscriptionGroupId, NotarialSubscriptionGroupId } = model;
  const { groupList, allChecked } = useSchoolEstablishmentGroups();

  return (
    <MyCard>
      <CardHeaderDetail title='Établissement scolaire' />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow label={Name.Label} sxTableCell={tableCell}>{name}</CaijTableRow>  
          <CaijTableRowConfirm 
            label={NotarialLawMasterOffer.Label} 
            sxTableCell={tableCell}
            confirm={notarialLawMasterOffer}
          />
          <CaijTableRow label={NotarialSubscriptionGroupId.Label} sxTableCell={tableCell}>
            { allChecked && groupList.find(s => s.id == notarialSubscriptionGroupId)?.name}
          </CaijTableRow> 
          <CaijTableRow label={SubscriptionGroupId.Label} sxTableCell={tableCell}>
            { allChecked && groupList.find(s => s.id == subscriptionGroupId)?.name}
          </CaijTableRow> 
          <CaijTableRowConfirm 
            label={ValidateEmailDomain.Label} 
            sxTableCell={tableCell}
            confirm={validateEmailDomain}
            sxTableRow={tableRow}
          />
        </TableBody>
      </Table>
    </MyCard>
  );
};

SchoolEstablishmentInfo.propTypes = {
  schoolEstablishment: PropTypes.object.isRequired
};

export default SchoolEstablishmentInfo;
