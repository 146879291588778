import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { deleteAction } from 'src/common';

interface CaijButtonDeleteProps {
  disabled?: boolean;
  onDelete?: () => void;
  children?: ReactNode;
  size?: 'small'| 'medium' | 'large'
}

const CaijButtonDelete: FC<CaijButtonDeleteProps> = ({ disabled, onDelete, size, children }) => {
  return (
    <>
      <Button 
        type='button' 
        sx={{...deleteAction(), borderColor: disabled ? '#696969' : ''}}
        startIcon={<DeleteIcon />} 
        disabled={disabled} 
        onClick={(e) => {
          e.stopPropagation(); 
          onDelete(); 
        }}
        size={size}
      >
        {children}
      </Button>
    </>
  );
};

CaijButtonDelete.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  onDelete: PropTypes.func
};

CaijButtonDelete.defaultProps = {
  disabled: false,
};

export default CaijButtonDelete;
