import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DocumentCollectionsResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody
} from '@mui/material';
import { DocCollectionsModel, tableRow } from 'src/common';
import { tableCell } from 'src/styles/docCollection';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow } from 'src/components/table/CaijTableRow';
import parse from 'html-react-parser';

export interface DocCollectionsEnInfoProps {
  model: DocCollectionsModel;
  collection: DocumentCollectionsResource;
}

const DocCollectionsEnInfo: FC<DocCollectionsEnInfoProps> = ({model,collection}) => {
  const { DescriptionEn, InfoBoxEn } = model;
  const { descriptionEn, infoboxEn } = collection;
  return (
    <MyCard>
      <CardHeaderDetail title="Collection" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow 
            sxTableCell={tableCell} 
            label={DescriptionEn.Label} 
            whiteSpace='pre-wrap'
          >
            { descriptionEn}
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} label={InfoBoxEn.Label}  sxTableRow={tableRow} whiteSpace='pre-wrap'>
            {parse(infoboxEn)}
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

DocCollectionsEnInfo.propTypes = {
  collection: PropTypes.object.isRequired
};

export default DocCollectionsEnInfo;
