import { useEffect, useReducer } from "react";
import { 
  _subscription, 
  fetchGroupList,
  Forbidden, 
  SubscriptionModel, 
  Unauthorized 
} from "src/common";

export function useSchoolEstablishmentGroups() {
  const initialState = { groupList: [], isLoading: true, isEmptyList: true};
  const [state, dispatch] = useReducer(_subscription, initialState);
  useEffect(() => {
      const abortController = new AbortController();
      (async () => {
          const model = new SubscriptionModel();
          model.skipHandleError = true;
          const results = await Promise.all([model.getGroups(), model.getSubscriptions()]);
          const [groups, subscriptions] = results;
          const { error } = model;
          if (error) {
            if (error.status === Forbidden || error.status === Unauthorized) {
              dispatch(fetchGroupList(groups, false, !Array.isArray(groups) || groups.length === 0, false));
            }else{
              dispatch(fetchGroupList(groups, false, !Array.isArray(groups) || groups.length === 0, true));
            } 
          }else{
            const newGroups = groups.map((group) => {
              group.name = `${subscriptions.find(({groups}) => groups.some(({id}) => id == group.id))?.name} --- ${group.name}`;
              return group;
            });
            dispatch(fetchGroupList(newGroups.sort((x1, x2) => x1.name.localeCompare(x2.name)), false, !Array.isArray(newGroups) || newGroups.length === 0, true));
          }
      })();
      return () => abortController.abort();
  },[]); 
  const { groupList, isLoading, isEmptyList, isAuthorize } = state;
  return { groupList, allChecked: (!isLoading && !isEmptyList && isAuthorize) };
}