import type { FC, ReactNode } from 'react';
import { Navigate  } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, isServiceAvailable } = useAuth();
  if(!isServiceAvailable){
    return <Navigate  to="/503" />;
  }
  if (!isAuthenticated) {
    return <Navigate  to="/login" />;
  }
  return (
    <>
      {children}
    </>
  );
};

export default AuthGuard;
