import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';

interface MyCardProps {
  children?: ReactNode;
  sx?: any;
}

const MyCard: FC<MyCardProps> = ({ children, sx }) => (
  <Card sx={{ ...sx, background: theme => theme.palette.background.paper }}>
    {children}
  </Card>
);

export default MyCard;
